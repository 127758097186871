import React, { useMemo, useState } from 'react';
import {
  MaterialReactTable,
  MRT_EditActionButtons,
  type MRT_ColumnDef,
  type MRT_Row,
  type MRT_TableOptions,
} from 'material-react-table';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material';
import { QueryClient, QueryClientProvider, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { PlaceModel, PlaceStatus } from '../models/place';
import { Link } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility'; 
import ArchitectureIcon from '@mui/icons-material/Architecture';


// Crear QueryClient
const queryClient = new QueryClient();

interface PlaceTableProps {
    initialPlaces?: PlaceModel[];
  }
  
  const PlaceTable: React.FC<PlaceTableProps> = ({ initialPlaces = [] }) => {    
    
    const [validationErrors, setValidationErrors] = useState<Record<string, string | undefined>>({});

  // Definir columnas de la tabla
  const columns = useMemo<MRT_ColumnDef<PlaceModel>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Centro',
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.name,
          helperText: validationErrors?.name,
          onFocus: () => setValidationErrors({ ...validationErrors, name: undefined }),
        },
      },
      {
        accessorKey: 'ateneaUrl',
        header: 'ERP URL',
        muiEditTextFieldProps: {
          type: 'url',
          required: true,
          error: !!validationErrors?.ateneaUrl,
          helperText: validationErrors?.ateneaUrl,
          onFocus: () => setValidationErrors({ ...validationErrors, ateneaUrl: undefined }),
        },
      },
      {
        accessorKey: 'odooDbUserName',
        header: 'Usuario DB',
        muiEditTextFieldProps: {          
          required: true,
          error: !!validationErrors?.odooDbUserName,
          helperText: validationErrors?.odooDbUserName,
          onFocus: () => setValidationErrors({ ...validationErrors, odooDbUserName: undefined }),
        },
      },
      {
        accessorKey: 'odooDbPassword',
        header: 'Password DB',     
        Cell: ({ row }: any) => <span title={row.original.odooDbPassword}>****</span>,   
        muiEditTextFieldProps: {   
          type: 'password',       
          required: true,
          error: !!validationErrors?.odooDbPassword,
          helperText: validationErrors?.odooDbPassword,
          onFocus: () => setValidationErrors({ ...validationErrors, odooDbPassword: undefined }),
        },
      },
      {
        accessorKey: 'odooMulticenterCode',
        header: 'Codigo de Centro',
        muiEditTextFieldProps: {          
          required: true,
          error: !!validationErrors?.odooMulticenterCode,
          helperText: validationErrors?.odooMulticenterCode,
          onFocus: () => setValidationErrors({ ...validationErrors, odooMulticenterCode: undefined }),
        },
      },
      {
        accessorKey: 'callCenter',
        header: 'Call Center',
        Cell: ({ row }: any) => <span>{row.original.callCenter ? 'Sí' : 'No'}</span>, // Mostrar "Sí" o "No" en lugar de true/false
        editVariant: 'select', // Cambiar a un menú desplegable en el modo de edición
        editSelectOptions: [
          { value: true, label: 'Sí' },
          { value: false, label: 'No' },
        ],
        muiEditTextFieldProps: {
          select: true,
          helperText: 'Seleccione "Sí" o "No"',
        },
      },
      /* {
        accessorKey: 'status',
        header: 'Status',
        editVariant: 'select',
        editSelectOptions: Object.values(PlaceStatus),
        muiEditTextFieldProps: {
          select: true,
          error: !!validationErrors?.status,
          helperText: validationErrors?.status,
        },
      }, */
    ],
    [validationErrors],
  );

  // CRUD Hooks
  const { mutateAsync: createPlace } = useCreatePlace();
  const { data: places = [], isLoading: isLoadingPlaces } = useGetPlaces();
  const { mutateAsync: updatePlace } = useUpdatePlace();
  const { mutateAsync: deletePlace } = useDeletePlace();

  // CREATE Action
  const handleCreatePlace: MRT_TableOptions<PlaceModel>['onCreatingRowSave'] = async ({ values, table }) => {
    const errors = validatePlace(values);
    if (Object.values(errors).some((error) => error)) {
      setValidationErrors(errors);
      return;
    }
    setValidationErrors({});
    await createPlace(values);
    table.setCreatingRow(null);
  };

  // UPDATE Action
  const handleSavePlace: MRT_TableOptions<PlaceModel>['onEditingRowSave'] = async ({ values, table }) => {
    const errors = validatePlace(values);
    if (Object.values(errors).some((error) => error)) {
      setValidationErrors(errors);
      return;
    }
    setValidationErrors({});
    try {
        debugger        
        // Obtén el ID del lugar que se está editando
        const editingRow = table.options.state.editingRow;
        const updatedPlace = { ...values, id: editingRow?.original.id }; // Usa `editingRow.original.id`
        await updatePlace(updatedPlace); // Actualiza el lugar usando tu hook
        queryClient.invalidateQueries({
            queryKey: ['places'], 
          }) // Refresca la tabla
        table.setEditingRow(null); // Sale del modo de edición
      } catch (err) {
        console.error('Error al guardar el lugar:', err);
      }
  };

  // DELETE Action
  const handleDeletePlace = (row: MRT_Row<PlaceModel>) => {
    if (window.confirm('Estas seguro de eliminar este centro?')) {
      deletePlace(row.original.id);
    }
  };

  return (
    <MaterialReactTable
      columns={columns}
      data={places}
      createDisplayMode="modal"
      editDisplayMode="modal"
      enableEditing
      getRowId={(row) => row.id}
      onCreatingRowSave={handleCreatePlace}
      onEditingRowSave={handleSavePlace}
      renderRowActions={({ row, table }) => (
        <Box sx={{ display: 'flex', gap: '1rem' }}>
          <Tooltip title="Editar centro">
            <IconButton onClick={() => table.setEditingRow(row)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton color="error" onClick={() => handleDeletePlace(row)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edita Mapa">
            <Link to={`map-editor/${row.original.id}`} style={{ textDecoration: 'none' }}>
                <IconButton color="primary">
                    <ArchitectureIcon />
                </IconButton>
            </Link>
          </Tooltip>
          <Tooltip title="Ver Mapa">
            <Link to={`/frontend/${row.original.id}`} style={{ textDecoration: 'none' }}>
                <IconButton color="primary">
                    <VisibilityIcon />
                </IconButton>
            </Link>
          </Tooltip>
        </Box>
      )}
      renderTopToolbarCustomActions={({ table }) => (
        <Button variant="contained" onClick={() => table.setCreatingRow(true)}>
          Crear Nuevo Centro
        </Button>
      )}
      state={{
        isLoading: isLoadingPlaces,
        showAlertBanner: false,
        showProgressBars: false,
      }}
    />
  );
};

// CRUD Hooks

function useCreatePlace() {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: async (place: Partial<PlaceModel>) => {
        const newPlace = new PlaceModel();
        Object.assign(newPlace, place);        
        return newPlace.save();
      },
      onSuccess: (newPlace) => {
        queryClient.setQueryData(['places'], (oldPlaces: PlaceModel[] | undefined) => {
          return [...(oldPlaces || []), newPlace]; // Añade el nuevo lugar al estado existente
        });
      },
    });
  }

function useGetPlaces() {
  return useQuery<PlaceModel[]>({
    queryKey: ['places'],
    queryFn: async () => PlaceModel.getAll({}),
    refetchOnWindowFocus: false,
  });
}

function useUpdatePlace() {
  const queryClient = useQueryClient();  
  return useMutation({
    mutationFn: async (place: Partial<PlaceModel>) => {
        if (!place.id) {
            throw new Error("El ID del lugar no está definido.");
          }
      const existingPlace = await PlaceModel.getById(place.id);
      if (existingPlace) {
        debugger
        Object.assign(existingPlace, place);
        return existingPlace.save();
      }
    },
    onSuccess: () => queryClient.invalidateQueries({
        queryKey: ['places'], 
      }),
  });
}

function useDeletePlace() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (id: string) => {
      const place = await PlaceModel.getById(id);
      if (place) {
        return place.destroy();
      }
    },
    onSuccess: () => queryClient.invalidateQueries({
        queryKey: ['places'], 
      }),
  });
}

// Validation function
function validatePlace(place: Partial<PlaceModel>) {
  return {
    name: !place.name ? 'Nomrbe es requerido' : '',    
    ateneaUrl: !place.ateneaUrl ? 'ERP URL es requerido' : '',
    odooDbUserName: !place.odooDbUserName ? 'Usuario DB es requerido' : '',
    odooDbPassword: !place.odooDbPassword ? 'Password DB es requerido' : '',
    odooMulticenterCode: !place.odooMulticenterCode ? 'Codigo Centro es requerido' : '',
    callCenter: !place.callCenter ? 'Gestión de Centro es requerido' : '',
  };
}

// Componente con QueryClientProvider
export const PlaceTableWithProvider: React.FC<{ initialPlaces?: PlaceModel[] }> = ({
    initialPlaces = [],
  }) => (
    <QueryClientProvider client={queryClient}>
      <PlaceTable initialPlaces={initialPlaces} />
    </QueryClientProvider>
  );
