import ReactDOM from 'react-dom/client';
import './index.css';

import reportWebVitals from './reportWebVitals';

import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css'
import 'leaflet/dist/leaflet.css'
// import 'bootstrap/dist/css/bootstrap.min.css';

import {
  createBrowserRouter,
  Route,
  RouterProvider,
  createRoutesFromElements
} from "react-router-dom";

import RootEl from './pages/Root';
import PlaceDetailsPage from './pages/admin/details';
import { initParse } from './utils';
import { placesLoader, placeLoader } from './loaders';
import { ErrorPage } from './components/ErrorPage';
import { AdminRootParent, AdminRoot } from './pages/admin/root';
import { PlaceListPage } from './pages/admin/list';
// import AuthProvider from 'react-auth-kit';
import LoginPage from './pages/auth/Login';
import PlaceDetailsFrontPage from './pages/frontend/details';
import { FrontendRoot, FrontendRootParent } from './pages/frontend/root';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


initParse(String(process.env.REACT_APP_PARSE_API_URL), String(process.env.REACT_APP_PARSE_APP_ID))

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" id='root' errorElement={<ErrorPage />} element={<RootEl />} >

      <Route path='admin' element={<AdminRootParent />}>
        <Route path='login' element={<LoginPage />} />
        <Route path='' element={<AdminRoot />}>
          <Route path="" element={<PlaceListPage />} loader={placesLoader} />
          <Route path='map-editor' >
            <Route path=":placeId" element={<PlaceDetailsPage />} loader={placeLoader} />
          </Route>
        </Route>
      </Route>

      <Route path="frontend" element={<FrontendRootParent />}  >
        <Route path='' element={<FrontendRoot />}>
          <Route path=":placeId" element={<PlaceDetailsFrontPage />} loader={placeLoader} />
        </Route>
      </Route>

    </Route>

  )
);

root.render(
  <RouterProvider router={router} />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
