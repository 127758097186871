import {
  Link,
  useLoaderData
} from "react-router-dom";

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { ListItemButton, ListItemText } from "@mui/material";
import { PlaceModel } from "../../models/place";
import { Grid } from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useAuth, useCurrentUser } from "../../providers/auth";

// Importa PlaceTable
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { PlaceTableWithProvider } from "../../components/PlaceTable";



const queryClient = new QueryClient();

export function PlaceListPage() {
  const routeData: any = useLoaderData();
  const places: PlaceModel[] = routeData['places']
  const user = useCurrentUser()
  console.debug('currentUser:', user)
  console.debug('routeData:', routeData)
  return (
    <>

      {/* Tabla de lugares */}
      <QueryClientProvider client={queryClient}>
        <PlaceTableWithProvider initialPlaces={places} /> {/* Puedes pasar datos iniciales */}
      </QueryClientProvider>
    </>
  );
}
