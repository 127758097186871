import * as React from 'react'
import { FeatureGroup, ImageOverlay, MapContainer, Marker, Popup, Rectangle, Polygon, Polyline, useMapEvents, GeoJSON as GeoJSONComp, Tooltip, useMap, useMapEvent } from 'react-leaflet'
// import * as L from 'leaflet'
import L, { Evented, FeatureGroup as LFeatureGroup, geoJSON, LatLng, LatLngBounds, Layer, LayerGroup, Map, latLng, PathOptions, LatLngExpression } from 'leaflet'
import { GeomanControls, layerEvents, } from 'react-leaflet-geoman-v2'
import { getImageDimensions, getBounds, throttle } from '../utils'
import { GeoJSON, Geometry, Polygon as GeojsonPolygon, Feature } from "geojson"
import { AteneaBoxStatus, Dimensions, MapFeatureCollection } from '../constants'
import { Button, Grid } from '@mui/material'
import { SpaceModel } from '../models/space'
import { useEffect, useRef } from 'react'
import './Mapplic.css';
import { ArrowForward } from '@mui/icons-material'

interface ItemPopupComponentProps {
  feature: Feature<GeojsonPolygon>
}
/* 
const ItemPopupComponent = (props: ItemPopupComponentProps): React.ReactNode => {
  const id = props.feature.id
  return (
    <Grid container>
      <Grid xs={6} item style={{ border: '1px red solid' }}>
        left {id}
      </Grid>
      <Grid item xs={6} style={{ border: '1px red solid' }}>
        right
      </Grid>
    </Grid>
  )
} */
const boxColors: Record<AteneaBoxStatus, { color: string; fillColor: string; fillOpacity: number, 'stroke-width'?: number }> = {
  [AteneaBoxStatus.AVAILABLE]: { color: "green", fillColor: "green", fillOpacity: 0.7, 'stroke-width': 0 },
  [AteneaBoxStatus.RENTED]: { color: "red", fillColor: "red", fillOpacity: 0.7, 'stroke-width': 0 },
  [AteneaBoxStatus.MAINTENANCE]: { color: "orange", fillColor: "orange", fillOpacity: 0.7 },
  [AteneaBoxStatus.RESERVED]: { color: "blue", fillColor: "blue", fillOpacity: 0.7 },
  [AteneaBoxStatus.UNKNOWN]: { color: "gray", fillColor: "gray", fillOpacity: 0.7 },
};


export interface GeomanProps {
  mapFileUrl?: string
  mapFileDimensions?: Dimensions
  geojson: MapFeatureCollection
  spaces: SpaceModel[]; // Agregar aquí la lista de espacios
  onMapItemClick?: (ev: any) => void
  onMapItemRemove?: (ev: any) => void
  onFeatureChange?: (ev: any) => void
  onChange?: (ev: MapFeatureCollection) => void
  mapOptions?: L.MapOptions
  ref?: any//React.MutableRefObject<GeomanHandler>
  itemPopupComponent?: (props: any) => React.ReactNode
}


export interface GeomanHandler {
  geojson: MapFeatureCollection,
  getGeojson(): MapFeatureCollection,
  //setGeojson: (geojson: MapFeatureCollection) => void
  map: Map,
  setMapFileUrl: (fileUrl: string) => void
  //refreshMap: () => void
  getLayerByUUID: (uuid: string) => L.Layer | undefined
}


const getCentroid = function (positions: L.LatLngLiteral[]): [number, number] {
  console.debug('getCentroid:', positions)
  let xSum = 0, ySum = 0;
  positions.forEach((position: L.LatLngLiteral) => {
    console.debug([position.lat, position.lng])
    xSum += position.lat;
    ySum += position.lng;
  });
  const n = positions.length;
  return [xSum / n, ySum / n];

}


interface RenderFeatureProps {
  map: Map,
  feature: Feature<GeojsonPolygon>
  spaces: SpaceModel[];
  zoomLevel: any
}
const RenderFeature_back = (localProps: RenderFeatureProps) => {
  const pmMap = (localProps.map.pm as any).map
  const feature = localProps.feature
  const isAssigned: boolean = (feature.properties as any).interactive == '1'
  const positions: L.LatLngExpression[] = feature.geometry.coordinates[0].map(v => ({ lat: v[1], lng: v[0] }));
  const pathOptions = isAssigned ? { color: 'blue', fillColor: 'blue' } : { color: 'gray', fillColor: 'gray' }
  const itemName = (feature.properties as any)?.name //|| (feature.properties as any)?.uuid
  const ref: any = React.useRef(null)
  const popupRef: any = React.useRef(null)




  const [centroid, setCentroId] = React.useState<L.LatLngExpression>()

  React.useEffect(() => {
    const layer: Layer = ref.current
    if (layer) {
      (layer as any).feature = feature
      setTimeout(() => {
        const center = (layer as L.Polygon).getCenter()
        setCentroId(center)
        console.debug('center:', center)
      }, 0);

    }
    return () => {

    }
  }, [])


  const onFeatureClick = (ev: any) => {
    console.debug('onFeatureClick')
    // if (props.onMapItemClick) {
    //   props.onMapItemClick({ layer: ev.target })
    // }
  }

  const onMouseOver = (ev: any) => {
    console.debug('onMouseOver')
    showPopup()
  }

  const onMouseOut = (ev: any) => {
    console.debug('onMouseOut')
    hidePopup()
  }

  const showPopup = () => {
    // console.debug('map:',pmMap)
    popupRef.current.toggle()
  }

  const hidePopup = () => {
    popupRef.current.close()
  }



  return <Polygon ref={ref} pathOptions={pathOptions} positions={positions} eventHandlers={{ click: onFeatureClick, mouseover: onMouseOver, mouseout: onMouseOut }}  >

    {/*     
    <Tooltip permanent>
      {itemName}
    </Tooltip> */}

    <Popup ref={popupRef} keepInView={true} minWidth={300}  >
      {itemName}
    </Popup>

    {centroid &&
      <Marker position={centroid} text={itemName} textMarker>

      </Marker>
    }

  </Polygon>
}

const RenderFeature = ({ map, feature, spaces, zoomLevel }: RenderFeatureProps) => {
  const positions: L.LatLngExpression[] = feature.geometry.coordinates[0].map(
    (v) => ({ lat: v[1], lng: v[0] })
  );
  const [hovered, setHovered] = React.useState(false); // Estado para manejar hover
  // Obtener los datos del box desde las propiedades del feature
  const itemName = (feature.properties as any)?.name;
  const meters = (feature.properties as any)?.meters;
  const ateneaUrl = (feature.properties as any)?.ateneaUrl;
  const isInteractive: boolean = (feature.properties as any).interactive == '1'


  const ateneaStatus = feature.properties?.ateneaStatus || AteneaBoxStatus.UNKNOWN;
  const statusLabel = (() => {
    switch (ateneaStatus) {
      case AteneaBoxStatus.AVAILABLE:
        return "Disponible";
      case AteneaBoxStatus.RENTED:
        return "Alquilado";
      case AteneaBoxStatus.MAINTENANCE:
        return "En mantenimiento";
      case AteneaBoxStatus.RESERVED:
        return "Reservado";
      case AteneaBoxStatus.UNKNOWN:
      default:
        return "Desconocido";
    }
  })();

  const price:number|null = feature.properties?.price
  const priceString:string = price ? (price.toFixed(2)+'€') : "-";
  const basePathOptions = boxColors[ateneaStatus as AteneaBoxStatus] || boxColors[AteneaBoxStatus.UNKNOWN];

  let fillOpacity: number
  if (isInteractive) {
    fillOpacity = hovered ? 1 : basePathOptions.fillOpacity
  } else {
    fillOpacity = .3
  }


  // Estilos basados en el estado del box
  const pathOptions = {
    ...basePathOptions,
    fillOpacity: fillOpacity
  };


  // Calcular el tamaño de la fuente basado en el nivel de zoom
  const calculateFontSize = (zoom: number): string => {
    const baseFontSize = 5; // Tamaño base de fuente
    const multiplier = 3; // Multiplicador para escalar
    return `${baseFontSize + zoom * multiplier}px`;
  };

  // Calcula el centroide del box
  const centroid = React.useMemo(() => {
    let latSum = 0, lngSum = 0;

    positions.forEach((pos) => {
      if (Array.isArray(pos)) {
        // Caso de tupla
        const [lat, lng] = pos;
        latSum += lat;
        lngSum += lng;
      } else {
        // Caso de objeto
        latSum += pos.lat;
        lngSum += pos.lng;
      }
    });

    const n = positions.length;
    return { lat: latSum / n, lng: lngSum / n };
  }, [positions]);

  // Configuración de un ícono vacío
  const emptyIcon = L.divIcon({
    className: "empty-icon", // Clase personalizada para un ícono invisible
    html: "", // Sin contenido HTML
    iconSize: [0, 0], // Tamaño de 0x0 para que no sea visible
  });

  const tooltipClassName = `custom-tooltip zoom-level-${zoomLevel}`
  // Renderizar el polígono y sus elementos
  return (
    <Polygon
      pathOptions={{ ...pathOptions, stroke: false }}
      positions={positions}
      eventHandlers={{
        mouseover: () => setHovered(true), // Activar hover
        mouseout: () => setHovered(false), // Desactivar hover
        click: () => console.log(`Clicked on ${itemName}`),
      }}
    >

      {isInteractive &&
        (
          <>
            <Popup className="custom-popup">
              <div style={{ padding: "10px", maxWidth: "200px" }}>
                <h3 style={{ margin: "0 0 10px", fontSize: "16px", fontWeight: "bold" }}>{itemName}</h3>
                <p style={{ margin: "5px 0", fontSize: "14px" }}>
                  <strong>Superficie:</strong> {meters} m²
                </p>
                <p style={{ margin: "5px 0", fontSize: "14px" }}>
                  <strong>PVP:</strong> {priceString}
                </p>
                <p style={{ margin: "5px 0", fontSize: "14px" }}>
                  <strong>Estado:</strong> {statusLabel}
                </p>
                <Button
                  variant="contained"
                  size="small"
                  style={{ backgroundColor: "#007bff", color: "#fff", marginTop: "10px" }}
                  endIcon={<ArrowForward />}
                  href={ateneaUrl}
                  target='_blank'
                >
                  Detalle Atenea
                </Button>
              </div>
            </Popup>

            {centroid &&
              <Tooltip
                permanent
                direction="center"
                className={`custom-tooltip`} // Clase dinámica basada en el zoom
              >
                <div className={`box-label zoom-level-${zoomLevel}`}
                  style={{ fontSize: calculateFontSize(zoomLevel) }}
                > {/* Contenedor para organizar los textos */}
                  <span className="box-name">{itemName}</span>
                  {meters && <span className="box-size">{`${meters} m²`}</span>}
                </div>
              </Tooltip>
            }
          </>
        )
      }

    </Polygon>
  );
};


const GeomanWrapperFront = React.forwardRef((props: GeomanProps, mainRef) => {
  console.debug('GeomanWrapper init')

  const [zoomLevel, setZoomLevel] = React.useState<number>(2); // Zoom inicial
  const map: Map = useMap();

  // Escuchar cambios en el nivel de zoom
  React.useEffect(() => {
    const handleZoom = () => {
      setZoomLevel(map.getZoom());
      console.log('zoom: ', map.getZoom());
    };
    map.on("zoomend", handleZoom);

    // Limpieza al desmontar el componente
    return () => {
      map.off("zoomend", handleZoom);
    };
  }, [map]);


  const emptyGeoJson: MapFeatureCollection = { type: 'FeatureCollection', features: [] }
  // const defaultMapContainerOptions: L.MapOptions = { zoom: 1, minZoom: 0, crs: L.CRS.Simple }
  const defaultMapContainerOptions: L.MapOptions = { crs: L.CRS.Simple }

  //const [geojson, setGeojson] = React.useState<MapFeatureCollection>(props.geojson || emptyGeoJson)
  const geoRef = React.useRef<LayerGroup>(null)

  const [mapFileUrl, setMapFileUrl] = React.useState<string | undefined>(props.mapFileUrl)
  const [imageOverLayBounds, setImageOverLayBounds] = React.useState<L.LatLngBoundsExpression>()
  const [mapOptions, setMapOptions] = React.useState<L.MapOptions>(defaultMapContainerOptions)
  const [isInited, setIsInited] = React.useState<boolean>(false)
  const itemPopupComponent = props.itemPopupComponent

  const geojson = props.geojson || { type: "FeatureCollection", features: [] };
  console.log('geojson en el wrapper:', geojson);

  const renderCount = useRef(0);
  useEffect(() => {
    renderCount.current += 1;
    console.log(`GeomanWrapperFront renderizado ${renderCount.current} veces`);
  });




  React.useEffect(() => {
    if (props.mapFileUrl && props.mapFileDimensions) {
      const bounds = getBounds(
        props.mapFileDimensions.width,
        props.mapFileDimensions.height
      );
      map.setMaxBounds(bounds);
      map.fitBounds(bounds);
    }
  }, [map, props.mapFileUrl, props.mapFileDimensions]);



  const setBackgroundImage = async (imageUrl: string) => {
    let width: number
    let height: number
    if (props.mapFileDimensions) {
      width = props.mapFileDimensions.width
      height = props.mapFileDimensions.height
    } else {
      const dims = await getImageDimensions(imageUrl);
      width = dims.width
      height = dims.height
    }
    const bounds = getBounds(width, height);
    // console.debug('bounds:', bounds)
    // setImageOverLayBounds(bounds)
    map.setMaxBounds(bounds)
    map.fitBounds(bounds)
  }

  const getLayerByUUID = (uuid: string): L.Layer | undefined => {
    if (!geoRef.current) {
      return
    }
    const layers = geoRef.current.getLayers()
    // console.debug('layers:', layers)
    const res = layers.find((l: any) => (l as any).feature?.properties?.uuid == uuid)
    return res
  }


  const getGeojson = (): MapFeatureCollection => {
    const gjson = geoRef.current?.toGeoJSON() as MapFeatureCollection;
    console.log('geojson:', gjson);
    return gjson
  }


  React.useImperativeHandle(mainRef, (): GeomanHandler => {
    return {
      geojson: geojson,
      map: map,
      setMapFileUrl: setMapFileUrl,
      getLayerByUUID: getLayerByUUID,
      getGeojson: getGeojson
    }
  }, [map, mapFileUrl, geojson, imageOverLayBounds])


  React.useEffect(() => {
    if (map && mapFileUrl) {
      setBackgroundImage(mapFileUrl)
    }
    return () => {
      console.debug('map remove')
      // map.remove();
    }
  }, [map, mapFileUrl])




  const eventDebugFn = (ev: any) => {
    //  console.debug(ev)
  }


  const onLayerClick = (ev: any) => {
    // console.debug('onLayerClick', ev)
    if (props.onMapItemClick) {
      props.onMapItemClick({ layer: ev.target })
    }
  }


  return (
    <FeatureGroup>
      {props.geojson.features.map((feature, idx) => (
        <RenderFeature key={idx} feature={feature} map={map} spaces={props.spaces} zoomLevel={zoomLevel} />
      ))}
    </FeatureGroup>
  );


});


export default GeomanWrapperFront


