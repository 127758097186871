import Parse from 'parse';
import { ParseQueryParams, ParseQueryResult, TGeometryType } from '../constants';
import { PlaceModel } from './place';
import { SpaceModel } from './space';
import { PlaceAreaModel } from './placeArea';
import type { FeatureCollection, Polygon as GeojsonPolygon, Feature } from 'geojson'


export interface GetFeatureItemQueryParams {
    placeId?: string
    areaId?: string
}



export class FeatureItemModel extends Parse.Object {


    static createByGeojson(placeArea: PlaceAreaModel, feature: Feature<GeojsonPolygon>): Promise<FeatureItemModel> {
        console.debug('createByGeojson:', feature)
        const geometry = feature.geometry
        const coordinates = geometry.coordinates
        const geometryType = geometry.type
        // const properties = feature['properties']
        // const uuid = properties['uuid']
        const attr = {
            place: placeArea.place,
            area: placeArea,
            type: 'Feature',
            geometryType: geometryType,
            coordinates: coordinates
        }
        const item = new this(attr)
        return item.save(null, {})
    }

    static getAll(params: GetFeatureItemQueryParams,extra?:ParseQueryParams): Promise<FeatureItemModel[]> {
        const query = new Parse.Query(this)
        const defaultParams: ParseQueryParams = { sort: { field: 'canonical', direction: 'asc' } }
        const fullParams:GetFeatureItemQueryParams&ParseQueryParams=Object.assign({}, defaultParams,extra, params)

         // Relación con `space`
        query.include("space");
        
        if (fullParams.placeId) {
            const placeQuery = new Parse.Query('Place').equalTo('objectId', fullParams.placeId)
            query.matchesQuery('place', placeQuery)
        }
        if (fullParams.areaId) {
            const areaQuery = new Parse.Query('PlaceArea').equalTo('objectId', fullParams.areaId)
            query.matchesQuery('area', areaQuery)
        }
        if (fullParams.sort) {
            if (fullParams.sort.direction == 'asc') {
                query.ascending(fullParams.sort.field)
            } else {
                query.descending(fullParams.sort.field)
            }
        }
        if (fullParams.limit) {
            query.limit(fullParams.limit)
        }
        return query.find()
    }

    static getById(placeId: string): Promise<FeatureItemModel | undefined> {
        const query = new Parse.Query(this).equalTo('objectId', placeId)
        return query.first()
    }

    static getBySpace(space: SpaceModel): Promise<FeatureItemModel | undefined> {
        const query = new Parse.Query(this).equalTo('space', space)
        return query.first()
    }

    constructor(attr?: object) {
        super('FeatureItem', attr);
    }

    get place(): PlaceModel {
        return this.get('place');
    }

    set place(val: PlaceModel) {
        this.set('place', val);
    }

    get space(): SpaceModel {
        return this.get('space');
    }

    set space(val: SpaceModel) {
        this.set('space', val);
    }

    get type() {
        return this.get('type');
    }

    set type(val) {
        this.set('type', val);
    }

    get geometryType(): TGeometryType {
        return this.get('geometryType');
    }

    set geometryType(val: TGeometryType) {
        this.set('geometryType', val);
    }

    get coordinates(): any[] {
        return this.get('coordinates');
    }

    set coordinates(val: any[]) {
        this.set('coordinates', val);
    }



}

Parse.Object.registerSubclass('FeatureItem', FeatureItemModel);