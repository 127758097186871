import Parse from 'parse';
import { ParseQueryParams, ParseQueryResult } from '../constants';
import type { FeatureCollection } from 'geojson'

export interface GetPlacesQueryParams extends ParseQueryParams {

}



export enum PlaceStatus {
    ACTIVE = "Active",
    DISABLED = "Disabled"
}

export class PlaceModel extends Parse.Object {
    

    static getAll(params: GetPlacesQueryParams): Promise<PlaceModel[]> {
        const query = new Parse.Query(PlaceModel)
        const defaultParams: Partial<GetPlacesQueryParams> = { sort: { field: 'canonical', direction: 'asc' } }
        params = Object.assign({}, defaultParams, params)
        if (params.sort) {
            if (params.sort.direction == 'asc') {
                query.ascending(params.sort.field)
            } else {
                query.descending(params.sort.field)
            }
        }

        if (params.limit) {
            query.limit(params.limit)
        }

        return query.find()
    }

    static getById(placeId:string): Promise<PlaceModel|undefined> {
        const query = new Parse.Query(this).equalTo('objectId',placeId)
        return query.first()      
    }

    constructor(attr?: object) {
        super('Place', attr);
    } 


    get name(): string {
        return this.get('name');
    }

    set name(value: string) {
        this.set('name', value);
    } 

    get ateneaUrl(): string {
        return this.get('ateneaUrl');
    }

    set ateneaUrl(value: string) {
        this.set('ateneaUrl', value);
    } 

    get slug(): string {
        return this.get('slug');
    }

    set slug(value: string) {
        this.set('slug', value);
    } 


    get odooDbUserName(): string {
        return this.get('odooDbUserName');
    }

    set odooDbUserName(value: string) {
        this.set('odooDbUserName', value);
    } 

    get odooDbPassword(): string {
        return this.get('odooDbPassword');
    }

    set odooDbPassword(value: string) {
        this.set('odooDbPassword', value);
    } 

    get odooMulticenterCode(): string {
        return this.get('odooMulticenterCode');
    }

    set odooMulticenterCode(value: string) {
        this.set('odooMulticenterCode', value);
    } 

    get callCenter(): boolean {
        return this.get('callCenter');
    }

    set callCenter(value: boolean) {
        this.set('callCenter', value);
    } 

   

}

Parse.Object.registerSubclass('Place', PlaceModel);