import Parse from 'parse';
import * as L from 'leaflet'
import { LatLngBoundsExpression, LatLngBounds, LatLng } from 'leaflet'
import { Dimensions } from './constants';
import { SpaceModel } from './models/space';

let debounceTimer: NodeJS.Timeout;
export const debounce = (func: () => void, delay: number) => {
  return (...args: any) => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};

let throttling = false;
export const throttle = (func: (...args: any) => void, delay: number) => {
  return (...args: any) => {
    if (throttling) {
      // console.debug("IGNORAMOS LLAMADA => throttling=true")
      return
    }
    throttling = true;
    func.apply(this, args);
    setTimeout(() => {
      throttling = false;
    }, delay);
  };
};

export async function fileToBase64(file: File): Promise<string> {
  console.log('Uploading file...');
  const formData = new FormData();
  formData.append('file', file);
  const result = await fetch('https://httpbin.org/post', {
    method: 'POST',
    body: formData,
  });
  const data = await result.json();
  const base64: string = data.files.file
  return base64
}

export function urlToBase64(fileUrl: string): Promise<string> {

  const arrayBufferToBase64 = (buffer: ArrayBuffer): string => {
    let res = (new TextDecoder("utf-8")).decode(buffer); //to UTF-8 text.
    res = unescape(encodeURIComponent(res));//to binary-string.
    res = btoa(res);
    return res//BASE64.
  }


  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      const reader = new FileReader();
      reader.onloadend = function () {
        if (!reader.result) {
          reject(`Vacio`)
          return
        }
        if (typeof reader.result == 'string') {
          resolve(reader.result)
          return
        }
        if (typeof reader.result == 'object') {
          resolve(arrayBufferToBase64(reader.result))
          return
        }
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', fileUrl);
    xhr.responseType = 'blob';
    xhr.send();
  })
}

export function getImageDimensions(imageURL: string): Promise<Dimensions> {
  const img = new Image();
  return new Promise((resolve, reject) => {
    img.onload = () => resolve({ width: img.width, height: img.height });
    img.onerror = reject;
    img.src = imageURL;
  });
}

export function getBounds(w: number, h: number): LatLngBoundsExpression {
  let x;
  let y;
  if (w / 2 >= h) {
    x = 180;
    y = h * (x / w);
  } else {// if (h > w || w / 2 < h)
    y = 90;
    x = w * (y / h);
  }
  let c1 = new LatLng(-y, -x);
  let c2 = new LatLng(y, x);
  return new LatLngBounds(c1, c2);
}


export function initParse(serverURL: string, appId: string) {  // let serverURL: string
  // if (process.env.REACT_APP_PARSE_SERVER_URL) {
  //   serverURL = process.env.REACT_APP_PARSE_SERVER_URL
  // } else {
  //   serverURL = `http://${window.location.hostname}:${process.env.REACT_APP_PARSE_SERVER_PORT}/${process.env.REACT_APP_PARSE_SERVER_MOUNT}`
  // } 
  console.debug(`initParse(serverURL:${serverURL},appId:${appId})`)
  Parse.serverURL = serverURL
  Parse.initialize(appId);
  // Parse.CoreManager.setStorageController(Parse.IndexedDB);
}


export function copyToClipBoard(text: string): Promise<void> {
  return navigator.clipboard.writeText(text)
}

export function validateGeoJSON(geojson: any): boolean {
  try {
    L.geoJSON(geojson, {})
    return true
  } catch (err) {
    return false
  }
}

export function parseQueryParams(url: string): any {
  const paramStr = url.split("?")[1]
  const searchParams = new URLSearchParams(paramStr);
  const res: any = {}
  searchParams.forEach((value: string, name: string, searchParams: URLSearchParams) => {
    res[name] = value
  })
  return res
}


