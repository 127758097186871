import React, { useState } from "react";
import { Divider, List, ListItem, ListItemText, Typography } from "@mui/material";
import { SpaceModel } from "../models/space";
import './Mapplic.css';

interface BoxListSearchProps {
    boxes: SpaceModel[]; // Lista de boxes basada en SpaceModel
    activeItemId?: string; // ID del box activo
    onBoxSelect: (box: SpaceModel) => void; // Callback para seleccionar un box
}

const BoxListSearchFront: React.FC<BoxListSearchProps> = ({
    boxes,
    activeItemId,
    onBoxSelect,
}) => {
    const [searchTerm, setSearchTerm] = useState<string>("");

    /** Filtrar boxes según el término de búsqueda */
    const filteredBoxes = boxes.filter((box) =>
        box.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    /** Agrupar los boxes por estado */
    const availableBoxes = filteredBoxes.filter((box) => box.isAvailable);
    const rentedBoxes = filteredBoxes.filter((box) => box.isRented);

    /** Determina las clases CSS dinámicas del box */
    const getClassName = (box: SpaceModel): string => {
        const isActive = activeItemId === box.id;
        const isAvailable = box.isAvailable;
        const isRented = box.isRented;

        return [
            isActive ? "item-active" : "",
            isAvailable ? "item-available" : "",
            isRented ? "item-rented" : "",
        ]
            .filter(Boolean)
            .join(" ");
    };

    /** Renderizar un grupo de boxes */
    const renderGroup = (
        title: string,
        boxes: SpaceModel[],
        color: string
    ) => {
        if (boxes.length === 0) return null; // No renderizar grupos vacíos

        return (
            <>
                <Typography
                    variant="subtitle1"
                    className="mapplic-dir-group-title"
                    style={{ color }}
                >
                    {title.toUpperCase()} ({boxes.length})
                </Typography>
                <List className="mapplic-dir-items">
                    {boxes.map((box) => (
                        <ListItem
                            key={box.id}
                            className={`mapplic-dir-item ${activeItemId === box.id ? "mapplic-active" : ""
                                }`}
                            onClick={() => onBoxSelect(box)}
                        >
                            {/* Thumbnail */}
                            <div
                                className={`mapplic-thumbnail ${box.isAvailable ? "available" : "rented"
                                    }`}
                            >
                                <span>{box.name.substring(0, 5)}</span>

                            </div>

                            {/* Información del box */}
                            <ListItemText
                                primary={box.name}
                                secondary={`${box.meters} m²`}
                                primaryTypographyProps={{ style: { fontWeight: "bold" } }}
                            />
                        </ListItem>
                    ))}
                </List>
            </>
        );
    };

    return (
        <div className="box-list">
            {/* Barra de búsqueda */}
            <div className="mapplic-search">

                <input
                    type="text"
                    placeholder="🔍 Buscar..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <button onClick={() => setSearchTerm("")}>

                </button>
            </div>

            {/* Resultados de búsqueda */}
            {filteredBoxes.length > 0 ? (
                <>
                    {renderGroup("Disponible", availableBoxes, "green")}
                    {renderGroup("Alquilado", rentedBoxes, "red")}
                </>
            ) : (
                <Typography variant="body1" style={{ color: "#999", textAlign: "center" }}>
                    No se encontraron resultados.
                </Typography>
            )}
        </div>
    );
};

export default BoxListSearchFront;
