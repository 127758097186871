import React, { ReactNode, useCallback, useEffect, useMemo, useState, useRef } from "react";
import Parse from 'parse';
import { Grid, Button, FormControl, MenuItem, Select, SelectChangeEvent, Box, Tab, Tabs } from "@mui/material";
import { Link, useLoaderData, useSearchParams } from "react-router-dom";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { PlaceModel } from "../../models/place";
import { ViewMode, MapFeatureCollection, Dimensions, AteneaBoxStatus } from "../../constants";
import { getBounds, urlToBase64 } from "../../utils";
import { SpaceModel } from "../../models/space";
import { FeatureItemModel, GetFeatureItemQueryParams } from "../../models/featureItem";
import { PlaceAreaModel } from "../../models/placeArea";
import * as L from 'leaflet';
import { ImageOverlay, MapContainer, MapContainerProps } from "react-leaflet";
import GeomanWrapperFront from "../../components/GeomanFront";
import BoxListSearchFront from "../../components/BoxListSearchFront";

// Configuración de URLs
const BASE_API_URL = process.env.REACT_APP_PARSE_BASE_API_URL;
const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;

const defaultMapContainerOptions: MapContainerProps = {
  zoom: 2, // Zoom inicial
  center: [0, 0],
  minZoom: 1, // Permite alejar más
  maxZoom: 5, // Permite acercar más
  zoomSnap: 0.5, // Suavidad en el zoom
  crs: L.CRS.Simple as L.CRS,
};



export default function PlaceDetailsFrontPage() {
  const routeData: any = useLoaderData();
  const place: PlaceModel = routeData['place'];
  const defaultArea: PlaceAreaModel = routeData['area'] || routeData['areas'][0];
  const areas: PlaceAreaModel[] = routeData['areas'];
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedArea, setSelectedArea] = useState<PlaceAreaModel>(defaultArea);
  const [geojson, setGeojson] = useState<MapFeatureCollection>({ type: 'FeatureCollection', features: [] });
  const [mapFileUrl, setMapFileUrl] = useState<string | undefined>();
  const [mapFileDims, setMapFileDims] = useState<Dimensions>();
  const [mapContainerOptions, setMapContainerOptions] = useState<MapContainerProps>(defaultMapContainerOptions);
  const [imageOverLayBounds, setImageOverLayBounds] = useState<L.LatLngBoundsExpression | undefined>();
  const [ready, setReady] = useState<boolean>(false);
  const [frontendUrl, setFrontendUrl] = React.useState<string>('')
  const [spaces, setSpaces] = useState<SpaceModel[]>([]);
  const [selectedSpace, setSelectedSpace] = useState<SpaceModel | null>(null);
  const [menuExpanded, setMenuExpanded] = React.useState(true); // Estado para manejar el menú

  const [tabValue, setTabValue] = useState<string>(defaultArea?.id || "");// Estado del tab seleccionado
  console.log('routeData', routeData);

  React.useEffect(() => {
    const areaIdParam = searchParams.get('areaId')
    if (areaIdParam && selectedArea && (areaIdParam != selectedArea.id)) {
      window.location.reload()
    }
  }, [searchParams, selectedArea])

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
    setSearchParams({ areaId : newValue });              
  };

  const loadBoxes = useCallback(async () => {
    console.debug("loadBoxes");
    try {
      const res = await SpaceModel.getAll({ placeId: place.id, areaId: selectedArea.id });
      console.debug("loadBoxes success", res.length);
      setSpaces(res);
    } catch (err) {
      console.error("Error al cargar los boxes:", err);
    }
  }, [place.id]);
  const renderCount = useRef(0);
  const loadFeatureItems = useCallback(async () => {
    console.debug("loadFeatureItems");


    renderCount.current += 1;
    console.log(`loadFeatureItems renderizado ${renderCount.current} veces`);


    try {
      // Establece parámetros para la consulta de FeatureItems
      const cloudParams: GetFeatureItemQueryParams = {};
      if (selectedArea) {
        cloudParams.areaId = selectedArea.id;
      }

      // Recuperar FeatureItems relacionados
      const featureItems = await FeatureItemModel.getAll(cloudParams);
      console.log("FeatureItems cargados:", featureItems);

      // Actualizar las propiedades de los Features en GeoJSON
      const updatedFeatures = geojson.features.map((feature) => {
        const featureItem = featureItems.find(
          (item) => item.id === (feature.properties as any).uuid
        );
        const space = featureItem?.get("space");        
        return {
          ...feature,
          properties: {
            ...feature.properties,
            ateneaStatus: space?.ateneaStatus || AteneaBoxStatus.UNKNOWN, // Estado actualizado
            meters: space?.meters,  // Metros2 del box
            price: space?.price,  // precio del box
            ateneaUrl: space?.ateneaUrl  // precio del box
          },
        };
      });

      // Actualizar el estado de GeoJSON
      setGeojson({
        ...geojson,
        features: updatedFeatures,
      });

      setReady(true);



    } catch (err) {
      console.error("Error al cargar los FeatureItems:", err);
    }
  }, [selectedArea, geojson.features]); // Solo dependemos de `geojson.features`


  const loadGeoJson = useCallback(async (selectedArea: PlaceAreaModel) => {
    console.debug("loadGeoJson");
    try {
      const response = await Parse.Cloud.run("placeArea:getGeoJson", { areaId: selectedArea.id });
      setGeojson(response);
    } catch (err) {
      console.error("Error al cargar GeoJSON:", err);
    }
  }, []);

  const init = useCallback(async () => {
    console.debug("init");

    setMapContainerOptions({
      ...defaultMapContainerOptions,
      ...selectedArea?.mapContainerOptions,
    });

    const mapUrl = `${BASE_API_URL}/mapplic/places/${place.id}/map_data.json`;
    setFrontendUrl(`${FRONTEND_URL}/?map=${encodeURI(mapUrl)}`);

    await loadBoxes();


    if (selectedArea) {

      await loadGeoJson(selectedArea); // Cargar el GeoJSON base  
      await loadFeatureItems(); // Asegúrate de que no hay dependencia cíclica

      if (selectedArea.mapFile) {
        const fileUrl = selectedArea.mapFile.url();
        const mapFileUrl = await urlToBase64(fileUrl);
        setMapFileUrl(mapFileUrl);

        const mapFileWidth = selectedArea.get("mapFileWidth");
        const mapFileHeight = selectedArea.get("mapFileHeight");

        if (mapFileWidth && mapFileHeight) {
          setMapFileDims({ width: mapFileWidth, height: mapFileHeight });
        }
      }
    }

    // Solo marcar como listo si `geojson` contiene `ateneaStatus`
    if (geojson.features.length > 1 && geojson.features.every((f) => f.properties?.ateneaStatus !== undefined)) {
      console.log('geojson en el init:', geojson);
      setReady(true);
    }
  }, [selectedArea, place, loadBoxes, loadGeoJson, loadFeatureItems]);




  const onAreaChange = (event: SelectChangeEvent) => {
    const areaId = event.target.value;
    setSearchParams({ areaId });
    const newArea = areas.find((area) => area.id === areaId);
    if (newArea) setSelectedArea(newArea);
  };

  //Carga la imagen de fondo
  useEffect(() => {
    if (mapFileUrl && mapFileDims) {
      const bounds = getBounds(mapFileDims.width, mapFileDims.height);
      setImageOverLayBounds(bounds);
    } else {
      setImageOverLayBounds(undefined);
    }
  }, [mapFileUrl, mapFileDims]);


  useEffect(() => {
    if (!ready) {
      init();
    }
  }, [init]);


  return (
    <Grid container style={{ height: "100vh" }}>
      {/* Header */}
      <Grid
        item
        xs={12}
        style={{
          padding: "10px 20px",
          borderBottom: "1px solid #ddd",
          backgroundColor: "#f9f9f9",
        }}
      >
        <Grid container spacing={2} alignItems="center">
          {/* Botón Atrás */}

          {/* Nombre del Lugar */}
          <Grid item xs>
            <h3 style={{ margin: 0, fontSize: "1.5rem", color: "#333" }}>
              {place.name}
            </h3>
          </Grid>

          {/* Selector de Áreas */}

          <Grid container spacing={2} alignItems="center">
            {/* Tabs en lugar del Select */}
            <Box
              sx={{
                width: "100%",
                borderBottom: 1,
                borderColor: "divider",
                display: "flex",
                justifyContent: "center", // Centra horizontalmente
                alignItems: "center", // Alinea verticalmente
              }}
            >
              {/* Contenedor de Tabs */}
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="Área de selección"
                centered // Centra las pestañas horizontalmente
                indicatorColor="primary"
                textColor="primary"
              >
                {areas.map((area) => (
                  <Tab key={area.id} label={area.name} value={area.id} />
                ))}
              </Tabs>
            </Box>
          </Grid>
        </Grid>
      </Grid>

      {/* Contenido */}
      <Grid item xs={12} style={{ display: "flex", height: "calc(100% - 60px)" }}>
        {/* Menú Izquierdo */}
        {/* Botón para expandir/colapsar */}
        <button
          className="toggle-button"
          onClick={() => setMenuExpanded(!menuExpanded)}
          style={{
            position: "absolute",
            top: "60%",
            left: menuExpanded ? "16%" : "1%", // Ajustar según el ancho del menú
            transform: "translateY(-50%)",
            zIndex: 1000, // Asegura que el botón siempre esté encima
          }}
        >
          {menuExpanded ? "←" : "→"}
        </button>

        {/* Menú lateral */}
        <div className={`menu-container ${menuExpanded ? "expanded" : "collapsed"}`}>
          <Grid
            item
            style={{
              borderRight: "1px solid #ddd",
              overflowY: "auto",
              backgroundColor: "#f7f7f7",
              height: "100%", // Asegurar que ocupe toda la altura
            }}
          >
            {/* Contenido del menú */}
            <BoxListSearchFront
              boxes={spaces} // Lista de SpaceModel
              activeItemId={selectedSpace?.id}
              onBoxSelect={(box) => setSelectedSpace(box)}
            />
          </Grid>
        </div>

        {/* Mapa */}
        <Grid item xs={12} style={{ position: "relative" }}>
          {mapFileUrl && geojson && (
            <div className="map-container" style={{ height: "100%", position: "relative" }}>
              <MapContainer {...mapContainerOptions} style={{ height: "100%", backgroundColor: 'rgb(242,242,242)' }}>
                {mapFileUrl && imageOverLayBounds && (
                  <ImageOverlay
                    url={mapFileUrl}
                    bounds={imageOverLayBounds}
                    opacity={1}
                    zIndex={10}
                    className="image-overlay"
                  />
                )}
                <GeomanWrapperFront
                  geojson={geojson}
                  mapOptions={mapContainerOptions}
                  mapFileUrl={mapFileUrl}
                  mapFileDimensions={mapFileDims}
                  spaces={spaces}
                />
              </MapContainer>

            </div>
          )}
        </Grid>

      </Grid>
    </Grid>
  );

}
